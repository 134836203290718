import React from "react";
import { Container } from "react-bootstrap";
import LegalH1 from "../components/legal-layout/LegalH1";
import LegalH2 from "../components/legal-layout/LegalH2";
import LegalText from "../components/legal-layout/LegalText";
import { translate } from "i18n";
import { LegalH3 } from "components/legal-layout";

const headerStyle = {
  fontFamily: "Helvetica",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "32px",
  textAlign: "center",
  color: "#F4F3CB",
  zIndex: 15,
};

const backgroundFAQ = {
  backgroundColor: "#171e26",
  color: "#FFFFFF",
  maxWidth: "100%",
  padding: "30px",
};

export default function Faq() {
  // TODO: Enable when having language specific page
  // useLangRedirect();
  return (
    <>
      <Container className="container-xl" style={backgroundFAQ}>
        <LegalH1>{translate("main_heading")}</LegalH1>

        <div>
          {/* <LegalH2>What is Wallace?</LegalH2> */}
          <LegalH2>{translate("what-is-wallace-q")}</LegalH2>
          <LegalText>{translate("what-is-wallace-a")}</LegalText>
        </div>

        <div>
          <LegalH2>{translate("is-wallace-safe-q")}</LegalH2>
          <LegalText>{translate("is-wallace-safe-a")}</LegalText>
        </div>

        <div>
          <LegalH2>{translate("why-use-wallace-q")}</LegalH2>
          <LegalText>{translate("why-use-wallace-a")}</LegalText>
        </div>

        <div>
          <LegalH2>{translate("reward-receive-with-wallace-q")}</LegalH2>
          <LegalH3>{translate("reward-receive-with-wallace-sub-q1")}</LegalH3>
          <LegalText>
            {translate("reward-receive-with-wallace-sub-a1")}
          </LegalText>
          <LegalH3>{translate("reward-receive-with-wallace-sub-q2")}</LegalH3>
          <LegalText>
            {translate("reward-receive-with-wallace-sub-a2")}
          </LegalText>
          <LegalH3>{translate("reward-receive-with-wallace-sub-q3")}</LegalH3>
          <LegalText>
            {translate("reward-receive-with-wallace-sub-a3")}
          </LegalText>
        </div>

        <div>
          <LegalH2>{translate("requirements-to-claim-rewards-q")}</LegalH2>
          <LegalText>
            <ul>
              <li>{translate("requirements-to-claim-rewards-a-list1")}</li>
              <li>{translate("requirements-to-claim-rewards-a-list2")}</li>
              <li>{translate("requirements-to-claim-rewards-a-list3")}</li>
              <li>{translate("requirements-to-claim-rewards-a-list4")}</li>
            </ul>
          </LegalText>
        </div>

        <div>
          <LegalH2>{translate("how-to-claim-pending-rewards-q")}</LegalH2>
          <LegalText>{translate("how-to-claim-pending-rewards-a")}</LegalText>
        </div>

        <div>
          <LegalH2>{translate("why-i-don't-get-rewards-q")}</LegalH2>
          <LegalH3>{translate("why-i-don't-get-rewards-a")}</LegalH3>
          <LegalText>
            {translate("why-i-don't-get-rewards-a-description")}
          </LegalText>
          <ul>
            <li>{translate("why-i-don't-get-rewards-a-description-list1")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list2")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list3")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list4")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list5")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list6")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list7")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list8")}</li>
            <li>{translate("why-i-don't-get-rewards-a-description-list9")}</li>
          </ul>

          <div>
            <LegalH2>{translate("how-tier-system-work-q")}</LegalH2>
            <LegalText>{translate("how-tier-system-work-a")}</LegalText>
            <LegalH2>{translate("tier-1-explorer-heading")}</LegalH2>
            <LegalText>{translate("tier-1-explorer-description")}</LegalText>
            <LegalH2>{translate("tier-2-adventurer-heading")}</LegalH2>
            <LegalText>{translate("tier-2-adventurer-description")}</LegalText>
            <LegalH2>{translate("tier-2-adventurer-q1")}</LegalH2>
            <LegalText>{translate("tier-2-adventurer-a1")}</LegalText>
            <LegalH2>
              <b>{translate("tier-2-adventurer-q2")}</b>
            </LegalH2>
            <LegalText>{translate("tier-2-adventurer-a2")}</LegalText>
            <LegalH2>{translate("tier-3-geoFusion-heading")}</LegalH2>
            <LegalText>{translate("tier-3-geoFusion-description1")}</LegalText>
            <LegalText>{translate("tier-3-geoFusion-description2")}</LegalText>
            <LegalText>{translate("tier-3-geoFusion-description3")}</LegalText>
            <LegalText>
              {translate("tier-3-geoFusion-sub-description3")}
            </LegalText>
            <LegalText>{translate("tier-3-geoFusion-description4")}</LegalText>
            <LegalText>
              {translate("tier-3-geoFusion-description5")}
              <a href="https://t.me/+DaNW-z-aZrRhODI1">
                {translate("tier-3-geoFusion-description5-link")}
              </a>
            </LegalText>
          </div>

          <div>
            <LegalH2>{translate("transfer-funds-top-wallace-q")}</LegalH2>
            <LegalText>{translate("transfer-funds-top-wallace-a")}</LegalText>
          </div>

          <div>
            <LegalH2>{translate("how-purchase-geo-q")}</LegalH2>
            <LegalText>{translate("how-purchase-geo-a")}</LegalText>
          </div>

          <div>
            <LegalH2>
              {translate("price-diff-between-coinMarket-wallace-q")}
            </LegalH2>
            <LegalText>
              {translate("price-diff-between-coinMarket-wallace-a")}
            </LegalText>
          </div>

          <div>
            <LegalH2>{translate("exchange-transfer-to-wallace-q")}</LegalH2>
            <LegalText>{translate("exchange-transfer-to-wallace-a")}</LegalText>
          </div>

          <div>
            <LegalH2>{translate("forgot-private-keys-q")}</LegalH2>
            <LegalText>{translate("forgot-private-keys-a1")}</LegalText>
            <LegalText>{translate("forgot-private-keys-a2")}</LegalText>
          </div>

          <div>
            <LegalH2>{translate("backup-restore-wallet-q")}</LegalH2>
            <LegalText>{translate("backup-restore-wallet-a")}</LegalText>
          </div>

          <div>
            <LegalH2>
              {translate("how-to-know-have-wallet-latest-version-q")}
            </LegalH2>
            <LegalText>
              {translate("how-to-know-have-wallet-latest-version-a")}
            </LegalText>
          </div>

          <div>
            <LegalH2>
              {translate("how-to-know-wallet-maintenance-affect-funds-q")}
            </LegalH2>
            <LegalText>
              {translate("how-to-know-wallet-maintenance-affect-funds-a")}
            </LegalText>
          </div>

          <div>
            <LegalH2>{translate("how-to-use-my-data-q")}</LegalH2>
            <LegalText>{translate("how-to-use-my-data-a")}</LegalText>
            <ul>
              <li>{translate("how-to-use-my-data-list1")}</li>
              <li>{translate("how-to-use-my-data-list2")}</li>
              <li>{translate("how-to-use-my-data-list3")}</li>
              <li>{translate("how-to-use-my-data-list4")}</li>
            </ul>
          </div>

          <div>
            <LegalH2>
              {translate("does-wallet-offer-support-in-multiLanguage-q")}
            </LegalH2>
            <LegalText>
              {translate("does-wallet-offer-support-in-multiLanguage-a")}
            </LegalText>
          </div>

          <div>
            <LegalH2>{translate("contact-wallace-team-q")}</LegalH2>
            <LegalText>
              {translate("contact-wallace-team-a")}{" "}
              <a href="mailto:support@wallacewallet.io">
                {translate("contact-wallace-team-email-a")}
              </a>
              {translate("contact-wallace-team-subtext-a")}
              <a href="https://twitter.com/wallace_wallet">
                {translate("contact-wallace-team-subtext-letter-x-a")}
              </a>
              {translate("contact-wallace-team-subtext-letter-or-a")}
              <a href="https://t.me/WallaceWallet">
                {translate("contact-wallace-team-subtext-word-telegram-a")}
              </a>
            </LegalText>
          </div>
          <LegalH2 style={headerStyle}>{translate("country_name")}</LegalH2>
        </div>

        {/* <div>
          <LegalH2>How do I earn rewards?</LegalH2>
          <LegalText>
            Wallace is the first crypto wallet that pays you just for using the
            app. You will get rewarded in 3 ways:
            <br />
            <br />
            i) Anonymously sharing your data. It’s time to take control! You are
            the owner of your digital profile and must be rewarded accordingly.
            Wallace pays you directly to your in-app wallet so you can trade
            your tokens at your will. The more you use the app the more you will
            be rewarded. And feel totally safe and secure.
            <br />
            <br />
            ii) Gamification and referral program. Jump on top of the rankings
            by sharing the app and inviting friends to Wallace. The more they
            participate the more you’ll earn on their behalf! You will earn
            rewards based on the data they generate, and based on the fees they
            generate while using swap capabilities and other crypto services.
            <br />
            <br />
            Wallace allows you to build a multi-layer reward system that will
            reward you whenever your friends generate data rewards or generate
            fees using the different crypto features within the app. Every time
            your referrals generate value, 1% of this value is distributed among
            the multi-layer reward mechanism:
            <ul>
              <li>
                Layer 1: Friends that you invite directly - You will get 50% of
                that 1%
              </li>
              <li>
                Layer 2: Friends that your direct friends invite - You will get
                30% of that 1%
              </li>
              <li>
                Layer 3: Friends that the friends of your friends invite - You
                will get 20% of that 1%
              </li>
            </ul>
            The more friends you bring, the bigger your reward mechanism can
            become, generating unlimited value for you.
            <br />
            <br />
            You will find your invitation code in your rewards user profile. All
            you need to do is share the download link with your friends and ask
            them to introduce your personal code once they are creating their
            rewards user.
            <br />
            <br />
            iii) By participating in different activities, like playing games,
            answering quick questionnaires, and watching content. Not only we
            can understand how to keep on upgrading your experience with
            Wallace, but you will also get rewarded for it. You will find a
            gaming section where you will be able to participate in different
            daily activities. The more you participate, there more rewards that
            you will receive: Watch2Earn, Answer2Earn, Play2Earn, etc.
          </LegalText>
        </div>

        <div>
          <LegalH2>How do I transfer money to Wallace?</LegalH2>
          <LegalText>
            You can send assets from any crypto exchange or crypto wallet app.
            To transfer crypto from an exchange, click withdraw in your exchange
            or ‘send’ in any other wallet app and enter the address of your
            Wallace crypto wallet.
            <br />
            <br />
            You can also buy crypto directly in Wallace through bank transfer,
            credit card, Google Pay, or Apple Pay using our "Transak" partner
            service.
          </LegalText>
        </div>

        <div>
          <LegalH2>Are there any DeFi options?</LegalH2>
          <LegalText>
            Yes! Buy, sell and exchange crypto in the simplest and safest
            possible way. Wallace brings you the most popular staking and
            earning opportunities in the market to maximize your profits. Access
            the hottest Defi trends directly from your app.
          </LegalText>
        </div>

        <div>
          <LegalH2>What does Multi-Chain mean?</LegalH2>
          <LegalText>
            It means that Wallace allows you to operate with the most popular
            blockchains and with different wallets and accounts. Therefore you
            can have your tokens anywhere you want at all times. Operate in this
            matrix of options in the simplest, easiest, and safest way.
            <br />
            <br />
            Currently available networks: Ethereum, Binance Chain, and Polygon.
            You will soon have access to the BTC chain, and many COSMOs
            networks.
          </LegalText>
        </div>

        <div>
          <LegalH2>How does the tier system work?</LegalH2>
          <LegalText>
            Wallace has a tier system based inside of the Rewards app section
            and allows you to obtain more rewards. It is based on the quantity
            of GEOs you own in your wallet, the more GEOs you have, the higher
            your tier will be and the more rewards you obtain. Current tiers
            are:
            <br />
            <br />
            <h5>
              <b>Tier 1</b>
            </h5>
            <ul>
              <li>If you own between 0 and 1000 GEOs</li>
            </ul>
            <br />
            <h5>
              <b>Tier 2</b>
            </h5>
            <ul>
              <li>If you own between 1001 and 5000 GEOs</li>
              <li>X2 rewards multiplier</li>
            </ul>
            <br />
            <h5>
              <b>Tier 3</b>
            </h5>
            <ul>
              <li>If you own between 5001 and 20000 GEOs</li>
              <li>X5 rewards multiplier</li>
            </ul>
            <br />
            <h5>
              <b>Tier 4</b>
            </h5>
            <ul>
              <li>Maximum tier. You own more than 20000 GEOs</li>
              <li>X10 rewards multiplier</li>
            </ul>
          </LegalText>
        </div>

        <div>
          <LegalH2>Why should I use Wallace?</LegalH2>
          <LegalText>
            We are helping to bring mass adoption to crypto. That’s why we have
            always focused on creating a product suitable for everyone. We’ve
            seen the crypto space growing as the most dynamic industry in the
            world. New networks and tokens are born every week and new users
            easily get lost when starting to trade. The lack of clear
            documentation has made people lose funds because of misplaced seed
            phrases or sending the wrong tokens to the wrong network. Wallace
            was created for simplifying the arrival of newcomers, pushing the
            cryptospace to the mainstream while allowing veteran users to keep
            using complex features. Download Wallace now for the most exquisite
            and premium crypto experience.
          </LegalText>
        </div>

        <div>
          <LegalH2>What are the requirements to claim rewards?</LegalH2>
          <LegalText>
            <ul>
              <li>You need to have generated more than 5 GEOs in rewards.</li>
              <li>You need more than 1 week of usage.</li>
              <li>You can’t be blacklisted.</li>
              <li>Be at tier 2 or higher.</li>
            </ul>
          </LegalText>
        </div>

        <div>
          <LegalH2>How do you use my data?</LegalH2>
          <LegalText>
            We store your data anonymously and we use it in different ways:
            <ul>
              <li>To build user advertising profiles.</li>
              <li>To build audiences with advertising purposes.</li>
              <li>To create market insights reports.</li>
              <li>
                To help consultancy companies with market insights and strategic
                projects.
              </li>
            </ul>
          </LegalText>
        </div>

        <div>
          <LegalH2>Contact us</LegalH2>
          <LegalText>
            If you have any suggestions, need support or need to contact us, you
            can do it through our email address{" "}
            <a href={"mailto:hello@wallacewallet.io"}>hello@wallacewallet.io</a>
            . You can also reach us on our{" "}
            <a href={"https://twitter.com/wallace_wallet"}>Twitter</a> or{" "}
            <a href={"https://t.me/WallaceWallet"}>Telegram</a>.
          </LegalText>
        </div>

        <h1 style={headerStyle}>United Kingdom</h1> */}
      </Container>
    </>
  );
}

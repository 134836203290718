import { AdjustIcon, GiftIcon, LockIcon } from 'icons'
import { translate } from 'i18n';

import 'styles/components/Features.scss';

function Features() {
  return (
    <section className="features">
      <div className="features__container">
        <div className="features__description">
          <h6 className="features__description--title">
            {translate('landing.features.title')}
          </h6>
          <p className="features__description--paragraph">
            {translate('landing.features.paragraph1')}
          </p>
          <p className="features__description--paragraph">
            {translate('landing.features.paragraph2')}
          </p>
        </div>
        <div className="features__bullets">
          <div className="features__bullets-item">
            <GiftIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.getPaid')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.getPaidDescription')}
            </p>
          </div>
          <div className="features__bullets-item features__bullets-item-second">
            <AdjustIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.multiChain')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.multiChainDescription')}
            </p>
          </div>
          <div className="features__bullets-item features__bullets-item-second">
            <LockIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.security')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.securityDescription')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;

import React, { useEffect } from 'react';
import usePersistedState from 'use-persisted-state-hook'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from 'components';
import { I18nProvider, LOCALES, isBrowserLanguageArabic } from 'i18n';
import {
  Academy,
  Cookies,
  Faq,
  GetPaidPage,
  Landing,
  MultiChainPage,
  Privacy,
  SecurityPage,
  Terms,
} from 'pages';

function App() {
  const [locale, setLocale] = usePersistedState("locale", LOCALES.en);

  useEffect(() => {
    if (isBrowserLanguageArabic()) {
      setLocale(LOCALES.ar);
    }
  }, [setLocale]);

  return (
    <I18nProvider locale={locale}>
      <div className={`app ${locale === LOCALES.ar ? 'app-ar': 'app-es'}`}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout setLocale={setLocale} />}>
              <Route path='/' element={<Landing />} />
              <Route path='/getpaid' element={<GetPaidPage />} />
              <Route path='/multi' element={<MultiChainPage />} />
              <Route path='/security' element={<SecurityPage />} />
              <Route path='/academy' element={<Academy />} />
            </Route>
            <Route path='/cookies' element={<Cookies />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/terms' element={<Terms />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </div>
    </I18nProvider>
  );
}

export default App;

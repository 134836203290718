import { LOCALES } from "i18n/constants";
import en from "i18n/translations/en.json";
import ar from "i18n/translations/ar.json";
import es from "i18n/translations/es.json";

const translations = {
  [LOCALES.en]: en,
  [LOCALES.ar]: ar,
  [LOCALES.es]: es,
};

export default translations;
